@import '../../../node_modules/normalize.css/normalize.css';
@import '../variables.scss';

/*
 * Base styles
 * ========================================================================== */

html {
	color: $text-color;
	font-family: $font-family-base;
	background: white;
}

@font-face {
	font-family: "ADAM.CG PRO";
	src: url("../../assets/ADAM.CG_PRO.otf") format("opentype");
}